import { useSplitTreatments } from "@splitsoftware/splitio-react";

export const flags = {
  CLARITY_ADMIN_LEGACY_USER_MANAGEMENT: "clarity-admin-legacy-user-management",
  MANAGE_ORGANISATIONS_WITH_IDEM: "manage-organisations-with-idem",
  CLARITY_ADMIN_PHISH_TAGS_V2: "clarity-admin-phish-tags-v2",
};

/**
 * @returns {boolean} true if feature is enabled, false otherwise
 * @param featureName
 */
export const useIsFeatureEnabled = (featureName) => {
  const { treatments } = useSplitTreatments({ names: [featureName] });
  return treatments?.[featureName]?.treatment === "on";
};
