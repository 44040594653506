import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { StyledCheckbox } from "../styles/general";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faClock } from "@fortawesome/free-solid-svg-icons";

export const StyledInput = styled.label`
  position: relative;
  font-size: 1.33rem;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;

  .description {
    font-style: italic;
  }

  .labeled-input {
    width: 100%;
    box-sizing: border-box;
    display: block;
    padding: ${(props) => !props.readOnly && "0.5rem 0.75rem"};
    margin: ${(props) => (props.readOnly ? "0.1rem -0.25rem 1rem" : "0.33rem 0 1rem 0")};
    font: inherit;
    font-size: ${(props) => (props.readOnly ? "1.5rem" : "1.33rem")};
    font-weight: ${(props) => props.readOnly && "bold"};
    color: ${(props) => props.theme.text};
    background: ${(props) => (props.readOnly ? "transparent" : props.theme.accentCardBackground)};
    border: ${(props) => (props.readOnly ? "0" : "1px")} solid
      ${(props) => (props.error ? "rgba(255, 0, 0, 0.8)" : props.theme.headerOutline)};
    border-radius: 0.25rem;
    outline: none;
    transition: all 0.3s;
    text-overflow: ellipsis;
    cursor: ${(props) => props.readOnly && "default"};
    &:focus-within {
      border-color: ${(props) => props.theme.secondaryButtonColor};
    }
  }

  input::-webkit-calendar-picker-indicator {
    opacity: ${(props) => props.icon && "0"};
    cursor: pointer;
  }

  input::-webkit-datetime-edit-day-field,
  input::-webkit-datetime-edit-month-field,
  input::-webkit-datetime-edit-year-field {
    color: ${(props) => props.theme.text};
  }

  svg.labeled-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    padding: 1.3rem;
    color: ${(props) => props.theme.lightText};
    pointer-events: none;
  }

  .editor-container {
    margin: 0.3rem 0 1rem;
  }
`;

export const Input = React.forwardRef(
  (
    {
      label,
      description,
      labelStyle,
      icon: _icon,
      readOnly,
      className,
      error: initialError,
      iconAction,
      placeholder: _placeholder,
      ...props
    },
    ref
  ) => {
    const [error, setError] = useState(initialError);

    useEffect(() => {
      if (initialError) setError(initialError);
    }, [initialError]);

    let icon = _icon;
    if (!icon) {
      if (props.type === "date") {
        icon = faCalendar;
        props.required = true;
      } else if (props.type === "time") {
        icon = faClock;
        props.required = true;
      }
    }

    let placeholder = readOnly ? "Not given" : _placeholder;

    // If input is of type 'checkbox', return a StyledCheckbox component instead
    if (props.type === "checkbox") {
      return (
        <StyledCheckbox>
          {label}
          <input key={props.defaultChecked} {...props} ref={ref} />
        </StyledCheckbox>
      );
    }

    if (props.type === "file" && readOnly) {
      props.type = "text";
    }

    const handleChange = (e) => {
      setError(null);
      if (typeof props.onChange === "function") props.onChange(e);
    };

    const renderInput = () => {
      return (
        <>
          {error && <FloatingError error={error} />}
          {props.children ? (
            React.Children.map(props.children, (child) =>
              React.cloneElement(child, { ...child.props, onChange: (e) => handleChange(e, child.props) })
            )
          ) : (
            <input
              {...props}
              key={props.defaultValue}
              readOnly={readOnly}
              onChange={handleChange}
              className="labeled-input"
              ref={ref}
              placeholder={placeholder}
            />
          )}
        </>
      );
    };

    return (
      <StyledInput style={labelStyle} className={className} icon={!!icon} error={!!error} readOnly={readOnly}>
        {label}
        {<span className="description">{description}</span>}
        {icon ? (
          <div style={{ position: "relative" }}>
            {icon && !readOnly && <FontAwesomeIcon icon={icon} className="labeled-icon" onClick={() => iconAction && iconAction()} />}
            {renderInput()}
          </div>
        ) : (
          renderInput()
        )}
      </StyledInput>
    );
  }
);

const StyledError = styled.div`
  position: absolute;
  bottom: calc(100% - 1.5rem);
  right: 0;
  font-size: 1.25rem;
  color: #ff585b;
  font-weight: bold;

  & > p {
    margin: 0;
    white-space: pre-wrap;
  }

  & + * {
    border-color: #ff585b !important;
  }
`;

const FloatingError = ({ error }) => {
  return (
    <StyledError>
      <p>{error}</p>
    </StyledError>
  );
};
