import React from "react";
import { PageContainer } from "lib/components/PageContainer";
import { ErrorHandledRoute } from "lib/components/ErrorHandledRoute";

import { Dashboard } from "./dashboard";
import { Tags } from "./tags";
import { Tickets } from "./tickets";
import { ListRules, SingleRule } from "./rules";
import { ListArtifacts, IOCArtifact } from "./artifacts";
import { useFetch, fetchData } from "lib/helpers/fetchData";
import { useIsFeatureEnabled, flags } from "lib/feature-flagging/treatments";

const loadTags = async ({ apiRoot }) => {
  return await fetchData(`${apiRoot}/tags`);
};

export const TagsContext = React.createContext({});

export const Phish = () => {
  const useNewPhishApi = useIsFeatureEnabled(flags.CLARITY_ADMIN_PHISH_TAGS_V2);
  const apiRoot = useNewPhishApi ? process.env.REACT_APP_PHISH_API_V2 : process.env.REACT_APP_PHISH_API;
  const tagsData = useFetch(loadTags, { apiRoot });

  const routes = [
    { name: "Tickets", link: "/phish/tickets", isWide: true },
    { name: "Artifacts", link: "/phish/artifacts", isWide: true },
    { name: "Rules", link: "/phish/rules" },
    { name: "Tags", link: "/phish/tags" },
  ];

  return (
    <TagsContext.Provider value={tagsData}>
      <PageContainer name="Phish" link="/phish" routes={routes}>
        <ErrorHandledRoute path="/phish/tickets" component={Tickets} />
        <ErrorHandledRoute path="/phish/artifacts/:artifactId" component={IOCArtifact} />
        <ErrorHandledRoute path="/phish/artifacts" component={ListArtifacts} />
        <ErrorHandledRoute path="/phish/rules/:id" component={SingleRule} />
        <ErrorHandledRoute path="/phish/rules" component={ListRules} />
        <ErrorHandledRoute path="/phish/tags" component={Tags} />
        <ErrorHandledRoute path="/phish" component={Dashboard} />
      </PageContainer>
    </TagsContext.Provider>
  );
};
