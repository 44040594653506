import React, { useState, useRef, useContext } from "react";
import styled from "styled-components";
import { useAsync } from "react-async";
import { fetchData, Spinner, Error } from "lib/helpers/fetchData";
import { useIsFeatureEnabled, flags } from "lib/feature-flagging/treatments";

import { Card } from "lib/components/Card";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { TagsContext } from "..";

const updateTag = async ([id, name, description, severity, apiRoot]) => {
  return await fetchData(`${apiRoot}/tags/${id}`, JSON.stringify({ name, description, severity }), "PUT");
};

const createTag = async ([name, description, severity, apiRoot]) => {
  return await fetchData(`${apiRoot}/tags`, JSON.stringify({ name, description, severity }), "POST");
};

const deleteTag = async ([id, apiRoot]) => {
  return await fetchData(`${apiRoot}/tags/${id}`, null, "DELETE");
};

const StyledTagsContainer = styled.div`
  width: 100%;
  position: relative;
  input {
    display: none;
    & + label > svg {
      transform: rotate(360deg);
      transition: all 0.3s;
    }
    &:checked + label > svg {
      transform: rotate(45deg);
    }
  }
  label {
    cursor: pointer;
    position: absolute;
    right: 0px;
    border: 1px solid ${(props) => props.theme.headerOutline};
    border-radius: 0.25rem;
    padding: 0.6rem 0.7rem;
    margin-bottom: 10px;
    font-size: 1rem;
    background: ${(props) => props.theme.cardBackground};
    transition: background 0.3s;
    color: ${(props) => props.theme.lightText};
    &:hover {
      background: ${(props) => props.theme.accentCardBackground};
    }
  }
`;

const StyledTag = styled(Card)`
  padding: 1rem;
  &:first-of-type {
    margin-top: 40px;
  }
  & > div {
    display: block;
  }
  header {
    display: flex;
    justify-content: space-between;
  }
  h3 {
    margin: 0.35rem 0;
  }
  section {
    flex: 1;
    display: flex;
    select {
      font: inherit;
      color: ${(props) => props.theme.text};
      font-size: 1.33rem;
      height: 24px;
      box-sizing: border-box;
      padding: 0 0.4rem;
      border-radius: 0.25rem;
      margin-left: 10px;
      border: 1px solid ${(props) => props.theme.headerOutline};
      background: ${(props) => props.theme.accentCardBackground};
      outline: none;
      :focus-within {
        border: 1px solid ${(props) => props.theme.secondaryButtonColor};
      }
    }
  }
  textarea {
    width: 100%;
    min-height: 150px;
    font: inherit;
    color: ${(props) => props.theme.text};
    font-size: 1.33rem;
    box-sizing: border-box;
    padding: 0.8rem;
    border-radius: 0.25rem;
    border: 1px solid ${(props) => props.theme.headerOutline};
    background: ${(props) => props.theme.accentCardBackground};
    outline: none;
    overflow: hidden;
    :focus-within {
      border: 1px solid ${(props) => props.theme.secondaryButtonColor};
    }
    &:first-child {
      min-height: unset;
      height: 2.3rem;
      width: unset;
      width: 60%;
      padding: 0.4rem 0.8rem;
      margin-bottom: 1rem;
    }
  }
  svg {
    cursor: pointer;
    margin: -4px 0 -4px 6px;
    font-size: 1rem;
    border: 1px solid ${(props) => props.theme.headerOutline};
    border-radius: 0.25rem;
    padding: 0.45rem;
    background: ${(props) => props.theme.cardBackground};
    transition: background 0.3s;
    &:hover {
      background: ${(props) => props.theme.accentCardBackground};
    }
  }
  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
`;

export const Tags = () => {
  const [showCreate, setShowCreate] = useState(false);

  const { data, error, isLoading, reload } = useContext(TagsContext);
  if (isLoading) return <Spinner />;
  if (error || data?.success === false) return <Error message={error} />;

  const refresh = () => {
    setShowCreate(false);
    reload();
  };

  return (
    <StyledTagsContainer>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <h2 style={{ color: "white" }}>
          Warning: Changing any tag names will require updating the corresponding categorisations in Cofense Triage.
        </h2>
        <input id="add" type="checkbox" onChange={() => setShowCreate(!showCreate)} />
        <label htmlFor="add">
          <FontAwesomeIcon icon={faPlus} />
        </label>
      </div>

      {showCreate && <Tag name="New tag" isCreate={true} refresh={refresh} />}
      {data.result.map((tag) => (
        <Tag key={tag.id} refresh={refresh} {...tag} />
      ))}
    </StyledTagsContainer>
  );
};

const SEVERITY_TEXT = {
  0: "Unknown",
  1: "Benign",
  2: "Suspicious",
  3: "Malicious",
};

const Tag = ({ name, description, severity: { score } = { score: 0 }, id, refresh, isCreate }) => {
  const useNewPhishApi = useIsFeatureEnabled(flags.CLARITY_ADMIN_PHISH_TAGS_V2);
  const apiRoot = useNewPhishApi ? process.env.REACT_APP_PHISH_API_V2 : process.env.REACT_APP_PHISH_API;

  const [hasChanged, setHasChanged] = useState(false);

  const formRef = useRef(null);

  const { run: runUpdate } = useAsync({ deferFn: updateTag, onResolve: () => refresh() });
  const { run: runCreate } = useAsync({ deferFn: createTag, onResolve: () => refresh() });
  const { run: runDelete } = useAsync({ deferFn: deleteTag, onResolve: () => refresh() });

  const handleUpdate = () => {
    let { name, severity, description } = formRef.current;
    const scoreInteger = parseInt(severity.value);
    severity = { score: scoreInteger, text: SEVERITY_TEXT[scoreInteger] };
    setHasChanged(false);
    runUpdate(id, name.value, description.value, severity, apiRoot);
  };

  const handleCreate = () => {
    let { name, severity, description } = formRef.current;
    const scoreInteger = parseInt(severity.value);
    severity = { score: scoreInteger, text: SEVERITY_TEXT[scoreInteger] };
    setHasChanged(false);
    runCreate(name.value, description.value, severity, apiRoot);
  };

  const handleDelete = () => runDelete(id, apiRoot);

  const handleChange = () => setHasChanged(true);

  return (
    <StyledTag>
      <form ref={formRef} onChange={handleChange}>
        <header>
          <section>
            <textarea name="name" defaultValue={name} />
            <select name="severity" defaultValue={(score || 0).toString()}>
              <option value="0">None</option>
              <option value="1">Benign</option>
              <option value="2">Suspicious</option>
              <option value="3">Malicious</option>
            </select>
          </section>
          {isCreate ? (
            <FontAwesomeIcon icon={faSave} onClick={handleCreate} />
          ) : (
            <span>
              {hasChanged && <FontAwesomeIcon icon={faSave} onClick={handleUpdate} />}
              <FontAwesomeIcon icon={faTrash} onClick={handleDelete} />
            </span>
          )}
        </header>
        <textarea name="description" style={{ height: "250px" }} defaultValue={description} />
      </form>
    </StyledTag>
  );
};
