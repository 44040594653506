import React, { useRef, useState } from "react";

import { Input } from "lib/components/Input";
import { CheckboxButton } from "lib/components/Checkbox";
import { KeyValue } from "lib/components/KeyValue";
import { useFetch, fetchData, Spinner, Error } from "lib/helpers/fetchData";

import { getFormValues } from "lib/helpers/getFormValues";
import { StyledFilters, StyledForm } from "lib/styles/general";

import { Modal, ConfirmModal } from "lib/components/Modal";
import { Searchbar } from "lib/components/Searchbar";
import { CTAButton } from "lib/components/CTAButton";
import { Table } from "lib/components/Table";
import { toSentenceCase } from "lib/helpers/toSentenceCase";

export const loadAliases = async ({ customerId }) => {
  return await fetchData(`${process.env.REACT_APP_ADMIN_API}/aliases/${customerId}`);
};

export const Phish = ({ data, reload }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = async () => {
    setIsLoading(true);
    let { enabled, domains, cc, useBrandlessTemplate } = getFormValues(formRef.current);
    domains = domains
      ?.toString()
      ?.split(",")
      ?.map((d) => d.trim())
      ?.filter(Boolean);

    await fetchData(
      `${process.env.REACT_APP_ADMIN_API}/customers/${data.id}/apps/phish`,
      JSON.stringify({ enabled, domains, cc, useBrandlessTemplate }),
      "PUT"
    );
    setIsLoading(false);
    setIsEditMode(false);
    reload();
  };

  const handleExit = () => setIsEditMode(false);

  return (
    <div style={{ position: "relative" }}>
      {!isEditMode && (
        <CTAButton style={{ position: "absolute", right: 0, top: 0, zIndex: 99 }} onClick={() => setIsEditMode(!isEditMode)}>
          Edit
        </CTAButton>
      )}
      <StyledForm ref={formRef}>
        <section style={{ paddingTop: 0 }}>
          <KeyValue
            text="Service Status"
            wrap={false}
            value={
              <CheckboxButton
                name="enabled"
                subtle
                showWhenNull
                label={!isEditMode ? (data?.apps?.phish?.enabled ? "Enabled" : "Disabled") : "Is Enabled?"}
                defaultChecked={!!data?.apps?.phish?.enabled}
                readOnly={!isEditMode}
              />
            }
          />
          <KeyValue
            text="Use Brandless Template"
            wrap={false}
            value={
              <CheckboxButton
                name="useBrandlessTemplate"
                subtle
                showWhenNull
                label={!isEditMode ? (data?.apps?.phish?.useBrandlessTemplate ? "Enabled" : "Disabled") : "Use Brandless Template?"}
                defaultChecked={!!data?.apps?.phish?.useBrandlessTemplate}
                readOnly={!isEditMode}
              />
            }
          />
          <Input name="domains" label="Domain(s)" defaultValue={data?.apps?.phish?.domains?.join(", ")} readOnly={!isEditMode} />
          {isEditMode && (
            <p style={{ fontStyle: "italic", color: "#FF585B", marginTop: 0 }}>
              Warning: Please do not configure the same email domain for multiple customers, as this is not currently supported.
            </p>
          )}
          <Input name="cc" type="email" label="CC" defaultValue={data?.apps?.phish?.cc} readOnly={!isEditMode} />
        </section>
        {!isEditMode && (
          <section>
            <Aliases customerId={data.id} />
          </section>
        )}
      </StyledForm>
      {isEditMode && (
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "1rem" }}>
          <CTAButton isSecondary onClick={handleExit}>
            Cancel
          </CTAButton>
          <CTAButton isLoading={isLoading} onClick={handleSubmit}>
            Save
          </CTAButton>
        </div>
      )}
    </div>
  );
};

export const Aliases = ({ customerId }) => {
  const [search, setSearch] = useState("");
  const [addAliasModal, setAddAliasModal] = useState({ showModal: false, callback: null, error: null });
  const [aliasModal, setAliasModal] = useState({ showModal: false, user: {} });
  const { data, isLoading, error, reload } = useFetch(loadAliases, { customerId, key: "aliases" });

  if (isLoading) return <Spinner />;
  if (error) return <Error message={error} />;

  const handleAddAlias = async (alias) => {
    setAddAliasModal({ showModal: true, isLoading: true });
    try {
      await fetchData(`${process.env.REACT_APP_ADMIN_API}/aliases/${customerId}`, JSON.stringify(alias), "POST");
      setAddAliasModal({ showModal: false, isLoading: false });
      reload();
    } catch (error) {
      setAddAliasModal({ showModal: true, isLoading: false, callback: handleAddAlias, error: error?.error?.message });
    }
  };

  const handleShowAddAlias = () => setAddAliasModal({ showModal: true, callback: handleAddAlias });

  const aliases =
    data?.result?.filter((u) => [u.displayName.toLowerCase(), u.email.toLowerCase()].join("").includes(search.toLowerCase())) || [];

  return (
    <div>
      <StyledFilters style={{ display: "flex" }}>
        <Searchbar style={{ margin: 0 }} setSearch={setSearch}>{`${aliases.length} aliases`}</Searchbar>
        <hr />
        <CTAButton onClick={handleShowAddAlias}>Add Alias</CTAButton>
      </StyledFilters>
      {aliases?.length ? (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th style={{ textAlign: "left" }}>Email</th>
            </tr>
          </thead>
          <tbody>
            {aliases
              .sort((a, b) => (b.email.toLowerCase() < a.email.toLowerCase() ? 1 : -1))
              .map((alias, i) => (
                <Alias key={i} alias={alias} setModal={setAliasModal} />
              ))}
          </tbody>
        </Table>
      ) : (
        <p style={{ width: "100%", textAlign: "center", fontSize: "1.5rem" }}>No aliases</p>
      )}
      <AliasModal
        key={aliasModal.alias?.id}
        {...aliasModal}
        hide={() => setAliasModal({ showModal: false })}
        reload={reload}
        customerId={customerId}
      />
      <AddAliasModal key={aliases.length} {...addAliasModal} hide={() => setAddAliasModal({ showModal: false })} />
    </div>
  );
};

const Alias = ({ alias, setModal }) => {
  const handleAliasClick = () => {
    setModal({ showModal: true, alias });
  };

  return (
    <tr style={{ cursor: "pointer" }} onClick={handleAliasClick}>
      <td>{alias.displayName}</td>
      <td style={{ textAlign: "left" }}>{alias.email}</td>
    </tr>
  );
};

const AliasModal = ({ showModal, hide, alias = {}, customerId, reload }) => {
  const [confirmModal, setConfirmModal] = useState({ showModal: false, callback: null });
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = async () => {
    const newAlias = getFormValues(formRef.current);
    setIsLoading(true);
    await fetchData(`${process.env.REACT_APP_ADMIN_API}/aliases/${customerId}/${alias.id}`, JSON.stringify(newAlias), "PUT");
    setIsLoading(false);
    reload();
    hide();
  };

  const handleDelete = async () => {
    await fetchData(`${process.env.REACT_APP_ADMIN_API}/aliases/${customerId}/${alias.id}`, null, "DELETE");
    setConfirmModal({ showModal: false, callback: null });
    reload();
    hide();
  };

  const handleDeletePrompt = () => {
    setConfirmModal({ showModal: true, callback: () => handleDelete() });
  };

  return (
    <Modal showModal={showModal} hide={hide} width="50rem">
      <div style={{ position: "relative", marginTop: "1rem" }}>
        {!isEditMode && setIsEditMode && (
          <CTAButton style={{ position: "absolute", right: 0, top: 0, zIndex: 99 }} onClick={() => setIsEditMode(!isEditMode)}>
            Edit
          </CTAButton>
        )}
        <StyledForm ref={formRef}>
          <section style={{ paddingTop: 0 }}>
            <Input name="email" label="Email" defaultValue={alias.email} readOnly={true} />
            <Input name="displayName" label="Display Name" defaultValue={alias.displayName} readOnly={!isEditMode} />
          </section>
          {!isEditMode && (
            <section>
              {/* eslint-disable-next-line */}
              <h3>Danger Zone 🚨🚨</h3>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid rgba(255, 0, 0, 0.6)",
                  borderRadius: "0.8rem",
                  padding: "1rem",
                }}
              >
                <p style={{ flex: 1, fontSize: "1.35rem", margin: 0 }}>Delete this alias permanently</p>
                <CTAButton onClick={handleDeletePrompt} style={{ color: "rgba(255, 0, 0, 0.7)", fontWeight: "bold" }} isSecondary>
                  Delete
                </CTAButton>
              </div>
            </section>
          )}
        </StyledForm>
        {isEditMode && (
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "1rem" }}>
            <CTAButton isSecondary onClick={() => setIsEditMode(false)}>
              Cancel
            </CTAButton>
            <CTAButton onClick={handleSubmit} isLoading={isLoading}>
              {setIsEditMode ? "Save" : "Create"}
            </CTAButton>
          </div>
        )}
      </div>
      <ConfirmModal {...confirmModal} hide={() => setConfirmModal({ showModal: false })} />
    </Modal>
  );
};

const AddAliasModal = ({ showModal, callback, error, isLoading, hide }) => {
  const [email, setEmail] = useState("");
  const formRef = useRef(null);

  const handleHide = () => {
    setEmail("");
    hide();
  };

  const handleAdd = () => {
    const values = getFormValues(formRef.current);
    callback(values);
  };

  const getDefaultName = () => {
    if (!email.includes("@")) return "";
    return toSentenceCase(email.split("@")[0].split(".").join(" "));
  };

  return (
    <Modal
      title="Add Alias"
      showModal={showModal}
      isLoading={!error && isLoading}
      hide={handleHide}
      actionText="Create"
      actionCommand={handleAdd}
    >
      <StyledForm ref={formRef}>
        <Input name="email" label="Email" onChange={(e) => setEmail(e.target.value)} error={error} />
        <Input name="displayName" label="Display Name" key={email + error} defaultValue={getDefaultName()} />
      </StyledForm>
    </Modal>
  );
};
